import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import {rootUri, hvnUriRoot , ranking , newfaceRanking , apiRoot, bustArr} from "./core/defines";

import { h2Unlinked } from "./core/core";
import SideNav from "./components/sideNav";
import axios from "axios";
import { useCastData } from "./service/castData";



function reserveBtn(hvnId){
    let id =""
    let linkTxt= "キャストを予約する"
    if(hvnId){
        id= `?girl_id=${hvnId}`
    }
    return(
        <a className="btn-ranking" href={`${hvnUriRoot}/A6ShopReservation/${id}`} target="_blank" rel="noreferrer noopener">
            {linkTxt}
        </a>
    )
}


function CastCard({cast, rank}){

    const Schedule=({sc})=>{
        return(
            <tr>
                <th>{sc.date}</th>
                <td>
                    {sc.sc 
                        ? <>{sc.sc_start}～{sc.sc_end}</>
                        : <>-</>
                    }
                </td>
            </tr>
        )
    }
    
    const Today=({schedule})=>{
        // 出勤データの先頭切り出し (出勤が今日から7日分という実装なので、先頭が当日)
        // 正直好きではないが早い & 現状のバックエンド基盤変える程の時間ないのでそういう実装
        return(
            schedule.sc
                ? <div className="sc-time">{schedule.sc_start}～{schedule.sc_end}</div>
                : <div className="sc-time"></div>
        )
    }

    return(
        cast ?
            <li className={`ranking-container -rank-${rank}`}>
                <div className={`ranking-number -rank-${rank}`}></div>
                <div className="ranking-cast">
                    <div className={`ranking-number-sp -rank-${rank}`}>No.{rank}</div>
                    <Link to={`/profile/${cast.code}`}>
                        <figure>
                            <img
                                src={`${rootUri}/d/p${cast.id}_1.jpg`}
                                alt={`${cast.name}のプロフィール写真`}
                            />
                            <figcaption className="txt-center">
                                <div className="info">
                                    <h3 className="name">{cast.name}{cast.age>0 && `(${cast.age})`}</h3>
                                    <p className="body">
                                        <span>T{cast.body.t} </span>
                                        <span>B{cast.body.b} ({bustArr[cast.body.cap]}) </span>
                                        <span>W{cast.body.w} </span>
                                        <span>H{cast.body.h} </span>
                                    </p>
                                </div>
                            </figcaption>
                        </figure>
                    </Link>
                </div>
                <div className="ranking-box">
                    <div className="ranking-top">
                        <Today schedule={cast.schedule[0]} />
                        <a  href={cast.url_diary} 
                            className="-diary">写メ日記を見る</a>
                    </div>
                    <div className="ranking-schedule">
                        <table className="schedule-table">
                            <tbody>
                                {cast.schedule.map((s, idx)=> <Schedule key={idx} sc={s} />)}
                            </tbody>
                        </table>
                    </div>
                    {reserveBtn(cast.c_id_hvn)}
                </div>
            </li>
        : null
    )
}



function Ranking(){

    const [initialized, setInitialized]=useState(false)
    const [rankers, setRankers]=useState([])
    const [newfaceRankers, setNewfaceRankers]=useState([])
    const {resetHvnId, resetCastData} = useCastData()

    useEffect(()=>{
        
        const animateTab=()=>{

            const tabs = document.querySelectorAll(".tab");

            tabs.forEach((tab, index) => {
                tab.addEventListener("click", function() {
                    document.querySelector(".active").classList.remove("active");
                    tab.classList.add("active");
        
                    const contents = document.querySelectorAll(".tab-contents");
                    contents.forEach(content => content.classList.remove("show"));
                    contents[index].classList.add("show");
                });
            });
        }

        const fetchRanking = async(ids, category)=>{
            const URI = apiRoot + "/ranking.php?cast=" + ids;
            await axios({
                method: "get",
                url: URI
                })
                .then(res=>{

                    console.log(category)
                    console.log(res.data)

                    if(category!="newface"){ 
                            setRankers(res.data) }
                    else{   setNewfaceRankers(res.data) }
                })
                .catch(err =>{
                    console.log("err", err)
                })
        }
        setInitialized(false)

        // ランカーデータ取得前処理
        let rankerID=""
        let nfRankerID=""
        ranking.forEach(r=> rankerID+=`${r.id},`)
        rankerID.slice(0, -1)
        newfaceRanking.forEach(r=> nfRankerID+=`${r.id},`)
        nfRankerID.slice(0, -1)

        fetchRanking(rankerID, "normal")
        fetchRanking(nfRankerID, "newface")

        
        setInitialized(true)
        animateTab()

        return()=>{
            resetHvnId()
            resetCastData()
        }
    },[]);



    return(

        <div className="container bg-w-alpha">
            <main id="ranking" className="main-colmn">
                <ul className="tab-area">
                    <li className="tab active">先月度本指名<span className="txt-block">ランキング</span></li>
                    <li className="tab">先月度新人指名<span className="txt-block">ランキング</span></li>
                </ul>

                <div className="tab-contents-area">

                    <div className="tab-contents show">
                        {initialized&&
                        <ul className="ranking-wrap">
                            {ranking.length>0 && ranking.map(r=>{

                                return <CastCard key={r.id} cast={rankers.find(c=> r.id== c.id)} rank={r.rank} />
                            })}

                        </ul>
                        }

                    </div>{/*  先月度本指名ランキング */}

                    <div className="tab-contents">
                        {initialized&&
                            <ul className="ranking-wrap">
                                {newfaceRankers.length>0 && newfaceRanking.map(r=>{

                                    return <CastCard key={r.id} cast={newfaceRankers.find(c=> r.id== c.id)} rank={r.rank} />
                                })}

                            </ul>
                        }

                    </div>{/*  先月度新人指名ランキング */}
                </div>
            </main>
            <SideNav />
        </div>
    )
}
export default Ranking;